import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
// import 'material-design-icons-iconfont/dist/material-design-icons.css';
import "vuetify/dist/vuetify.min.css";
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
/* Theme variables */
import './theme/variables.css';
// import { aliases, md } from 'vuetify/iconsets/md';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  // icons: {
  //   defaultSet: 'md',
  //   aliases,
  //   sets: {
  //     mdi,
  //   },
  // },
    components,
    directives
});

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(vuetify)

router.isReady().then(() => {
  app.mount('#app');
});