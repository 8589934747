import { createRouter } from '@ionic/vue-router';
import { createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import NetToGross from '../views/NetToGross.vue'
import GrossToNet from '../views/GrossToNet.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/net-to-gross',
    name: 'NetToGross',
    component: NetToGross
  },
  {
    path: '/gross-to-net',
    name: 'GrossToNet',
    component: GrossToNet
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
