
import {
  IonContent,
  IonPage,
  IonRow,
  IonGrid,
  IonCol,
  IonFooter,
  IonCardSubtitle,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NetToGross",
  props: {
    timeout: { type: Number, default: 1000 },
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonGrid,
    IonFooter,
    IonCardSubtitle,
  },
  data() {
    return {
      search: "",
      netToGrossTable: [
        {
          id: 1,
          net_wage: 40000,
          gross_wage: 45045,
          employee_social_security_contribution: 4279,
          employee_health_insurance_contribution: 766,
          employer_social_security_contribution: 6757,
          employer_health_insurance_contribution: 766,
          income_tax: 0,
          total_payment: 12568,
          payment_ratio: "31%",
          company_expenses: 7523,
          expense_ratio: 0.167,
        },
        {
          id: 2,
          net_wage: 45000,
          gross_wage: 53364,
          employee_social_security_contribution: 5070,
          employee_health_insurance_contribution: 907,
          employer_social_security_contribution: 8005,
          employer_health_insurance_contribution: 907,
          income_tax: 2387,
          total_payment: 17276,
          payment_ratio: "38%",
          company_expenses: 8912,
          expense_ratio: 0.167,
        },
        {
          id: 3,
          net_wage: 50000,
          gross_wage: 59960,
          employee_social_security_contribution: 5696,
          employee_health_insurance_contribution: 1019,
          employer_social_security_contribution: 8994,
          employer_health_insurance_contribution: 1019,
          income_tax: 3245,
          total_payment: 19973,
          payment_ratio: "40%",
          company_expenses: 10013,
          expense_ratio: 0.167,
        },
        {
          id: 4,
          net_wage: 55000,
          gross_wage: 67414,
          employee_social_security_contribution: 6404,
          employee_health_insurance_contribution: 1146,
          employer_social_security_contribution: 10112,
          employer_health_insurance_contribution: 1146,
          income_tax: 4864,
          total_payment: 23672,
          payment_ratio: "43%",
          company_expenses: 11258,
          expense_ratio: 0.167,
        },
        {
          id: 5,
          net_wage: 60000,
          gross_wage: 74010,
          employee_social_security_contribution: 7031,
          employee_health_insurance_contribution: 1258,
          employer_social_security_contribution: 11102,
          employer_health_insurance_contribution: 1258,
          income_tax: 5721,
          total_payment: 26370,
          payment_ratio: "44%",
          company_expenses: 12360,
          expense_ratio: 0.167,
        },
        {
          id: 6,
          net_wage: 65000,
          gross_wage: 80607,
          employee_social_security_contribution: 7658,
          employee_health_insurance_contribution: 1370,
          employer_social_security_contribution: 12091,
          employer_health_insurance_contribution: 1370,
          income_tax: 6579,
          total_payment: 29068,
          payment_ratio: "45%",
          company_expenses: 13461,
          expense_ratio: 0.167,
        },
        {
          id: 7,
          net_wage: 70000,
          gross_wage: 87202,
          employee_social_security_contribution: 8284,
          employee_health_insurance_contribution: 1482,
          employer_social_security_contribution: 13080,
          employer_health_insurance_contribution: 1482,
          income_tax: 7436,
          total_payment: 31764,
          payment_ratio: "45%",
          company_expenses: 14562,
          expense_ratio: 0.167,
        },
        {
          id: 8,
          net_wage: 75000,
          gross_wage: 93800,
          employee_social_security_contribution: 8911,
          employee_health_insurance_contribution: 1595,
          employer_social_security_contribution: 14070,
          employer_health_insurance_contribution: 1595,
          income_tax: 8294,
          total_payment: 34465,
          payment_ratio: "46%",
          company_expenses: 15665,
          expense_ratio: 0.167,
        },
        {
          id: 9,
          net_wage: 80000,
          gross_wage: 100396,
          employee_social_security_contribution: 9538,
          employee_health_insurance_contribution: 1707,
          employer_social_security_contribution: 15059,
          employer_health_insurance_contribution: 1707,
          income_tax: 9151,
          total_payment: 37162,
          payment_ratio: "46%",
          company_expenses: 16766,
          expense_ratio: 0.167,
        },
        {
          id: 10,
          net_wage: 85000,
          gross_wage: 106992,
          employee_social_security_contribution: 10164,
          employee_health_insurance_contribution: 1819,
          employer_social_security_contribution: 16049,
          employer_health_insurance_contribution: 1819,
          income_tax: 10009,
          total_payment: 39860,
          payment_ratio: "47%",
          company_expenses: 17868,
          expense_ratio: 0.167,
        },
        {
          id: 11,
          net_wage: 90000,
          gross_wage: 113589,
          employee_social_security_contribution: 10791,
          employee_health_insurance_contribution: 1931,
          employer_social_security_contribution: 17038,
          employer_health_insurance_contribution: 1931,
          income_tax: 10867,
          total_payment: 42558,
          payment_ratio: "47%",
          company_expenses: 18969,
          expense_ratio: 0.167,
        },
        {
          id: 12,
          net_wage: 95000,
          gross_wage: 120185,
          employee_social_security_contribution: 11418,
          employee_health_insurance_contribution: 2043,
          employer_social_security_contribution: 18028,
          employer_health_insurance_contribution: 2043,
          income_tax: 11724,
          total_payment: 45256,
          payment_ratio: "48%",
          company_expenses: 20071,
          expense_ratio: 0.167,
        },
        {
          id: 13,
          net_wage: 100000,
          gross_wage: 126781,
          employee_social_security_contribution: 12044,
          employee_health_insurance_contribution: 2155,
          employer_social_security_contribution: 19017,
          employer_health_insurance_contribution: 2155,
          income_tax: 12582,
          total_payment: 47953,
          payment_ratio: "48%",
          company_expenses: 21172,
          expense_ratio: 0.167,
        },
        {
          id: 14,
          net_wage: 105000,
          gross_wage: 133377,
          employee_social_security_contribution: 12671,
          employee_health_insurance_contribution: 2267,
          employer_social_security_contribution: 20007,
          employer_health_insurance_contribution: 2267,
          income_tax: 13439,
          total_payment: 50651,
          payment_ratio: "48%",
          company_expenses: 22274,
          expense_ratio: 0.167,
        },
        {
          id: 15,
          net_wage: 110000,
          gross_wage: 139975,
          employee_social_security_contribution: 13298,
          employee_health_insurance_contribution: 2380,
          employer_social_security_contribution: 20996,
          employer_health_insurance_contribution: 2380,
          income_tax: 14297,
          total_payment: 53351,
          payment_ratio: "49%",
          company_expenses: 23376,
          expense_ratio: 0.167,
        },
        {
          id: 16,
          net_wage: 115000,
          gross_wage: 146570,
          employee_social_security_contribution: 13924,
          employee_health_insurance_contribution: 2492,
          employer_social_security_contribution: 21986,
          employer_health_insurance_contribution: 2492,
          income_tax: 15154,
          total_payment: 56048,
          payment_ratio: "49%",
          company_expenses: 24478,
          expense_ratio: 0.167,
        },
        {
          id: 17,
          net_wage: 120000,
          gross_wage: 153167,
          employee_social_security_contribution: 14551,
          employee_health_insurance_contribution: 2604,
          employer_social_security_contribution: 22975,
          employer_health_insurance_contribution: 2604,
          income_tax: 16012,
          total_payment: 58746,
          payment_ratio: "49%",
          company_expenses: 25579,
          expense_ratio: 0.167,
        },
        {
          id: 18,
          net_wage: 125000,
          gross_wage: 159762,
          employee_social_security_contribution: 15177,
          employee_health_insurance_contribution: 2716,
          employer_social_security_contribution: 23964,
          employer_health_insurance_contribution: 2716,
          income_tax: 16869,
          total_payment: 61442,
          payment_ratio: "49%",
          company_expenses: 26680,
          expense_ratio: 0.167,
        },
        {
          id: 19,
          net_wage: 130000,
          gross_wage: 166359,
          employee_social_security_contribution: 15804,
          employee_health_insurance_contribution: 2828,
          employer_social_security_contribution: 24954,
          employer_health_insurance_contribution: 2828,
          income_tax: 17727,
          total_payment: 64141,
          payment_ratio: "49%",
          company_expenses: 27782,
          expense_ratio: 0.167,
        },
        {
          id: 20,
          net_wage: 135000,
          gross_wage: 172955,
          employee_social_security_contribution: 16431,
          employee_health_insurance_contribution: 2940,
          employer_social_security_contribution: 25943,
          employer_health_insurance_contribution: 2940,
          income_tax: 18584,
          total_payment: 66838,
          payment_ratio: "50%",
          company_expenses: 28883,
          expense_ratio: 0.167,
        },
        {
          id: 21,
          net_wage: 140000,
          gross_wage: 179202,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3046,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3046,
          income_tax: 19396,
          total_payment: 68710,
          payment_ratio: "49%",
          company_expenses: 29508,
          expense_ratio: 0.165,
        },
        {
          id: 22,
          net_wage: 145000,
          gross_wage: 185064,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3146,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3146,
          income_tax: 20158,
          total_payment: 69672,
          payment_ratio: "48%",
          company_expenses: 29608,
          expense_ratio: 0.16,
        },
        {
          id: 23,
          net_wage: 150000,
          gross_wage: 190926,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3246,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3246,
          income_tax: 20920,
          total_payment: 70634,
          payment_ratio: "47%",
          company_expenses: 29708,
          expense_ratio: 0.156,
        },
        {
          id: 24,
          net_wage: 155000,
          gross_wage: 196787,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3345,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3345,
          income_tax: 21682,
          total_payment: 71594,
          payment_ratio: "46%",
          company_expenses: 29807,
          expense_ratio: 0.151,
        },
        {
          id: 25,
          net_wage: 160000,
          gross_wage: 203001,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3451,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3451,
          income_tax: 22790,
          total_payment: 72914,
          payment_ratio: "46%",
          company_expenses: 29913,
          expense_ratio: 0.147,
        },
        {
          id: 26,
          net_wage: 165000,
          gross_wage: 209641,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3564,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3564,
          income_tax: 24317,
          total_payment: 74667,
          payment_ratio: "45%",
          company_expenses: 30026,
          expense_ratio: 0.143,
        },
        {
          id: 27,
          net_wage: 170000,
          gross_wage: 216282,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3677,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3677,
          income_tax: 25845,
          total_payment: 76421,
          payment_ratio: "45%",
          company_expenses: 30139,
          expense_ratio: 0.139,
        },
        {
          id: 28,
          net_wage: 175000,
          gross_wage: 222922,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3790,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3790,
          income_tax: 27372,
          total_payment: 78174,
          payment_ratio: "45%",
          company_expenses: 30252,
          expense_ratio: 0.136,
        },
        {
          id: 29,
          net_wage: 180000,
          gross_wage: 229562,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 3903,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 3903,
          income_tax: 28899,
          total_payment: 79927,
          payment_ratio: "44%",
          company_expenses: 30365,
          expense_ratio: 0.132,
        },
        {
          id: 30,
          net_wage: 185000,
          gross_wage: 236201,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4015,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4015,
          income_tax: 30426,
          total_payment: 81678,
          payment_ratio: "44%",
          company_expenses: 30477,
          expense_ratio: 0.129,
        },
        {
          id: 31,
          net_wage: 190000,
          gross_wage: 242842,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4128,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4128,
          income_tax: 31954,
          total_payment: 83432,
          payment_ratio: "44%",
          company_expenses: 30590,
          expense_ratio: 0.126,
        },
        {
          id: 32,
          net_wage: 195000,
          gross_wage: 249482,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4241,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4241,
          income_tax: 33481,
          total_payment: 85185,
          payment_ratio: "44%",
          company_expenses: 30703,
          expense_ratio: 0.123,
        },
        {
          id: 33,
          net_wage: 200000,
          gross_wage: 256122,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4354,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4354,
          income_tax: 35008,
          total_payment: 86938,
          payment_ratio: "43%",
          company_expenses: 30816,
          expense_ratio: 0.12,
        },
        {
          id: 34,
          net_wage: 205000,
          gross_wage: 262762,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4467,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4467,
          income_tax: 36535,
          total_payment: 88691,
          payment_ratio: "43%",
          company_expenses: 30929,
          expense_ratio: 0.118,
        },
        {
          id: 35,
          net_wage: 210000,
          gross_wage: 269402,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4580,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4580,
          income_tax: 38062,
          total_payment: 90444,
          payment_ratio: "43%",
          company_expenses: 31042,
          expense_ratio: 0.115,
        },
        {
          id: 36,
          net_wage: 215000,
          gross_wage: 276043,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4693,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4693,
          income_tax: 39590,
          total_payment: 92198,
          payment_ratio: "43%",
          company_expenses: 31155,
          expense_ratio: 0.113,
        },
        {
          id: 37,
          net_wage: 220000,
          gross_wage: 282683,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4806,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4806,
          income_tax: 41117,
          total_payment: 93951,
          payment_ratio: "43%",
          company_expenses: 31268,
          expense_ratio: 0.111,
        },
        {
          id: 38,
          net_wage: 225000,
          gross_wage: 289322,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 4918,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 4918,
          income_tax: 42644,
          total_payment: 95702,
          payment_ratio: "43%",
          company_expenses: 31380,
          expense_ratio: 0.108,
        },
        {
          id: 39,
          net_wage: 230000,
          gross_wage: 295962,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 5031,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 5031,
          income_tax: 44171,
          total_payment: 97455,
          payment_ratio: "42%",
          company_expenses: 31493,
          expense_ratio: 0.106,
        },
        {
          id: 40,
          net_wage: 235000,
          gross_wage: 302603,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 5144,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 5144,
          income_tax: 45699,
          total_payment: 99209,
          payment_ratio: "42%",
          company_expenses: 31606,
          expense_ratio: 0.104,
        },
        {
          id: 41,
          net_wage: 240000,
          gross_wage: 309243,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 5257,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 5257,
          income_tax: 47226,
          total_payment: 100962,
          payment_ratio: "42%",
          company_expenses: 31719,
          expense_ratio: 0.103,
        },
        {
          id: 42,
          net_wage: 245000,
          gross_wage: 315883,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 5370,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 5370,
          income_tax: 48753,
          total_payment: 102715,
          payment_ratio: "42%",
          company_expenses: 31832,
          expense_ratio: 0.101,
        },
        {
          id: 43,
          net_wage: 250000,
          gross_wage: 322523,
          employee_social_security_contribution: 16760,
          employee_health_insurance_contribution: 5483,
          employer_social_security_contribution: 26462,
          employer_health_insurance_contribution: 5483,
          income_tax: 50280,
          total_payment: 104468,
          payment_ratio: "42%",
          company_expenses: 31945,
          expense_ratio: 0.099,
        },
      ],
      headers: [
        { title: "Paga Neto", value: "net_wage" },
        { title: "Totali", value: "total_payment" },
        { title: "Total / P.Neto", value: "payment_ratio" },
        { title: "Paga Bruto", value: "gross_wage" },
        {
          title: "Sig.Shoqerore(Punonjes)",
          value: "employee_social_security_contribution",
        },
        {
          title: "Sig.Shendetsore(Punonjes)",
          value: "employee_health_insurance_contribution",
        },
        {
          title: "Sig.Shoqerore(Punedhenes)",
          value: "employer_social_security_contribution",
        },
        {
          title: "Sig.Shendetesore(Punedhenesi)",
          value: "employer_health_insurance_contribution",
        },
        { title: "TAP", value: "income_tax" },

        { title: "Shpenzimet e Kompanise", value: "company_expenses" },
        { title: "Shpenzime / P.Bruto", value: "expense_ratio" },
      ],
    };
  },

  methods: {
    redirectToHome() {
      this.$router.push({ name: "Home" });
    },
    isOnlyNumberAllowed(evt: any) {
      evt = evt || window.event;

      let charCode = evt.which || evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) && // Not a number
        charCode !== 46 && // Not a period (.)
        charCode !== 44 // Not a comma (,)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatNumber(value: any) {
      //function for changing the format of no only with 2 no after the comma.
      if (value == null) {
        return 0;
      } else {
        let num = parseFloat(value);
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
  },
});
